export interface PageParams {
	country: string
	language: string
}

export interface PageProps {
	params: PageParams
}

export const defaultParams = {
	country: 'gb',
	language: 'en',
}
