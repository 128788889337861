import type { CSSProperties } from 'react'

interface Styles {
	container: CSSProperties
	title: CSSProperties
	button: CSSProperties
}

export const styles: Styles = {
	container: {
		height: '65dvh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
		margin: '1rem',
	},
	title: {
		fontWeight: '600',
		fontSize: '0.8125rem',
		lineHeight: '1.125rem',
		fontFamily: 'var(--mango-primary-font)',
		marginBottom: '1rem',
		textTransform: 'uppercase',
	},
	button: {
		fontSize: '0.8125rem',
		fontWeight: 600,
		fontFamily: 'var(--mango-primary-font)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '0.25rem',
		cursor: 'pointer',
		boxSizing: 'border-box',
		margin: '2rem 0 0',
		border: 0,
		height: '2.75rem',
		padding: '0 2rem',
		minWidth: '350px',
		color: '#fff',
		backgroundColor: '#131313',
	},
}
